import React from 'react'
import { Link } from 'react-router-dom'

export default function FooterCompo() {
  return (
    <div >
        <footer>
        <p className="copyright">Copyrights © {(new Date().getFullYear())} | <Link style={{color:"white",textDecoration:"none"}} to="/PrivacyPolicy"> Privacy-Policy</Link> </p>
    </footer>
    </div>
  )
}
