import React from 'react'

export default function PrivacyPolicyCompo() {
  return (
    <div style={{margin:"40px"}}>
       <h2>
            Privacy Policy</h2>
        <h3>
            WhatWeb takes your privacy very seriously</h3>
        <h5>
            Privacy statement</h5>
        <p>
            WhatWeb has created this privacy statement to demonstrate our commitment to your
            right to privacy. This document will tell you how and for what purposes we use your
            information. We are very sensitive to privacy issues on the Internet and in general
            have no objection to User accessing its website without providing any personal information.
            However there are circumstances like those listed below when WhatWeb may request
            you to provide your personal information.
        </p>
        <h5>
            Collection of personal information
        </h5>
        <p>
            WhatWeb does not collect any of your personal information and does not store any
            personal information on the server.
        </p>
        <h5>
            Collecting information for on line surveys
        </h5>
        <p>
            WhatWeb does not collect any of your personal information for any online or offline
            surveys.
        </p>
        <h5>
            Contact Informations
        </h5>
        <p>
            If you believe that WhatWeb has not adhered to this privacy statement, please contact
            us and we take the necessary action.
        </p>
    </div>
  )
}
