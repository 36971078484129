import React from "react";
 import logo from "../../../src/images/what-web.png";
import { Link } from "react-router-dom";
export default function HeaderCompo() {
  return (
    <div>
      <header>
        <h2>
      <Link to="/" style={{color:"white",textDecoration:"none"}} >  <img src={logo} alt="logo" style={{verticalAlign: "middle"}} />
           &nbsp;WhatWeb.in </Link>
        </h2>
      </header>
    </div>
  );
}
