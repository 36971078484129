import "./App.css";
import "./css/StyleSheet.css";
import HeaderCompo from "./components/common/HeaderCompo";
import FooterCompo from "./components/common/FooterCompo";
import HomeCompo from "./components/HomeCompo";
import PrivacyPolicyCompo from "./components/PrivacyPolicyCompo";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import DemoState from "./components/context/DemoState";
import EncrDycrState from "./components/context/encrDycrState";
function App() {
  return (
    <>
      <DemoState>
        <EncrDycrState>
        {/* <Router basename="/mywhat"> */}
        <Router >
          <HeaderCompo />
          <div style={{ minHeight: "550px", marginTop: "80px" }}>
            <Routes>
              <Route path="/" element={<HomeCompo />} />
              <Route path="/PrivacyPolicy" element={<PrivacyPolicyCompo />} />
            </Routes>
          </div>
          <FooterCompo />
        </Router>
        </EncrDycrState>
      </DemoState>
    </>
  );
}

export default App;
