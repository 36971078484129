import DemoContext from "./demoContext";

const DemoState = (props) => {
  const addMobileNumber = async (mobilenumber) => {
    //  const response = await fetch(`https://www.whatweb.in/demoApi.aspx?mob=${mobilenumber}`);
    const response = await fetch(
      `https://www.whatweb.in/DemoApiLog.aspx?mob=${mobilenumber}`,
      { method: "POST" }
    );
   // console.log(response);
    //const note = 
    await response.json();
  //  console.log(note);
  };

  return (
    <DemoContext.Provider value={{ addMobileNumber }}>
      {props.children}
    </DemoContext.Provider>
  );
};
export default DemoState;
