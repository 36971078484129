import React, { useState, useContext } from "react";
import logoPlayStore from "../../src/images/Google-Play-Icon-Right.png";
import { isMobile } from "react-device-detect";
import demoContext from "./context/demoContext";
import encrDycrContext from "./context/encrDycrContext";

const HomeCompo = () => {
  const SentMsg = (event) => {
    event.preventDefault();
    // try to write file
    addMobileNumber(textWhatApp);
    if (isMobile) {
      window.open(
        `https://web.whatsapp.com/send?phone=91+${textWhatApp}&text&app_absent=0`,
        "_blank",
        "noopener,noreferrer"
      );
    } else {
      window.open(
        `https://web.whatsapp.com/send?phone=91+${textWhatApp}`,
        "_blank",
        "noopener,noreferrer"
      );
    }
  };
  const handelOnChange = (event) => {
    setTextWhatApp(event.target.value);
  };
  const [textWhatApp, setTextWhatApp] = useState("");

  /////////////////////////////////
  // declare context, context can be used any where in the project, ref video 58
  const myContext = useContext(demoContext);
  // get function out from the whole context
  const { addMobileNumber } = myContext;

  const myEncrDycrContext = useContext(encrDycrContext);
  const { generateKeyNew, encrypt, decrypt } = myEncrDycrContext;

  const handelSave = async () => {
    // called the context function
    // await   addMobileNumber(textWhatApp);
    const myKey = await generateKeyNew();
    console.log(myKey);
    //var data='kbcdefgh 1()+';
    var data = "kbcdefgh";
    console.log(data);
    var strEncryptData = await encrypt(data, myKey);
    console.log(strEncryptData);
    var strdecrypt = await decrypt(strEncryptData, myKey);
    console.log(strdecrypt);
  };

  return (
    <div>
      <form onSubmit={SentMsg}>
        <div>
         {/* eslint-disable-next-line */}
          <marquee width="95%" direction="left" height="20px" className="text-danger">
           Note:- This app working only when you are using your whatsApp in browser. Please enter valid whatsApp number without country code and press button to open whatsApp chat window.
          </marquee>
        </div>
        <div className="container">
          <div className="card bg-light shadow p-3 mb-5  rounded ">
            <div className="card-body text-center">
              <div>
                <div className="row">
                  <div className="container">
                    <input
                      style={{ fontSize: "25px" }}
                      type="text"
                      id="txtWhatsAppNo"
                      placeholder="Enter Mobile Number"
                      autoComplete="off"
                      title="Please Enter Mobile Number"
                      required
                      value={textWhatApp}
                      onChange={handelOnChange}
                    />
                  </div>
                </div>
                <div className="row mt-2 mb-3">
                  <div className="container">
                    {/* <input
              type="button"
              value="OPEN CHAT SCREEN"
              id="btnopenchat"
              onClick={SentMsg}
            /> */}
                    <button
                      type="submit"
                      className="btnopenchat"
                      id="btnopenchat"
                      title="Press this button to open the chat window"
                    >
                      OPEN CHAT SCREEN
                    </button>
                    <button
                      type="button"
                      onClick={handelSave}
                      style={{ display: "none" }}
                    >
                      Save Number
                    </button>
                  </div>
                </div>
                <div className="row mt-4 mb-2">
                  <div className="">
                    <b>बिना सेव नंबर के मैसेज भेजें। </b>
                  </div>
                </div>
                <div className="row">
                  <div className="">
                    <b>
                      कृपया व्हाट्सएप में संदेश भेजने के लिए फोन नंबर दर्ज करें
                      और सबमिट बटन दबाएं।
                    </b>
                  </div>
                </div>
                <div className="row mt-4 mb-4">
                  <div className="">
                    <b>
                      Please enter phone number to message in WhatsApp and press
                      Submit button.
                    </b>
                  </div>
                </div>
                <div className="row mt-1 mb-5">
                  <div className="">
                    <a
                      href={
                        "https://play.google.com/store/apps/details?id=com.anitsolution.whatweb"
                      }
                      rel="noreferrer"
                      target={"_blank"}
                      title="Click here to Download Android app"
                    >
                      <img
                        src={logoPlayStore}
                        alt="logo"
                        style={{ verticalAlign: "middle" }}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
export default HomeCompo;
