import EncrDycrContext from "./encrDycrContext";
const EncrDycrState = (props) => {
  const charset =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789%";

  const generateKeyNew = async () => {
    var str = "";
    var tar1 = [];
    for (var c = 0; c < charset.length; c++) {
      // tar1.Add(charset.substring(c, 1));
      tar1.push(charset.substring(c, c + 1));
    }
    //console.log(tar1);
    // const _r = Math.random();
    const min = 1;
    const max = 63;
    // const rand = min + Math.random() * (max - min);
    // Get random chars and replace the same from array ti reduce the length
    for (var cc = 0; cc < charset.length; cc++) {
      const ii = Math.ceil(min + Math.random() * (max - min));
      // str.Append(tar1[ii]);
      str += tar1[ii];
    }
    return str;
  };
  ///// encryption function
  const encrypt = async (v, keyReal) => {
    /******* Replace the special chars *********/
    v = encodeURI(v);
    v = v.replace("!", "%21");
    v = v.replace("+", "%20");
    v = v.replace(".", "%2E");
    v = v.replace("-", "%2D");
    v = v.replace("*", "%2A");
    v = v.replace("(", "%28");
    v = v.replace(")", "%29");
    v = v.replace("_", "%5F");
    var rotate;
    var str = "";
    var key = "";
    var key2 = "";
    key = keyReal;
    console.log(v);
    if (keyReal.length > 0 && v.length > 0) {
      for (var c = 0; c < v.length; c++) {  
        var ch = charset.indexOf(v.substring(c, c+1));
        console.log('index of : '+v.substring(c, c+1));
        //rotate = v.charCodeAt(v.substring(c, c+1));
        rotate = v.charCodeAt(v.indexOf(v.substring(c, c+1)));
        if (ch > -1) {
          console.log('i am if')
          console.log('key sub :'+key.substring(ch, ch+1));
          str += key.substring(ch, ch+1);
          console.log('i am str : '+str);
          key2=await rotateKey(key, parseInt(rotate));
          key="";
          key = key2;
          key2="";
        }
      }
      return str;
    } else {
      return "";
    }
  };
  /////////// decrypt
   const decrypt = async (v, keyReal) => {
    var rotate;
    var str = "";
    var key = charset;
    if (keyReal.length > 0 && v.length > 0)
    {
        for (var c = 0; c < v.length; c++)
        {
            var ch = keyReal.indexOf(v.substring(c, c+1));
            rotate = key.charCodeAt(key.indexOf(key.substring(ch, ch+1)));
            if (ch > -1)
            {
                str += key.substring(ch, ch+1);
               var myvar= parseInt(rotate);
                key =await rotateKey(key,-myvar );
            }
        }
        /*****************************************/
        str = str.replace("%21", "!");
        str = str.replace("%20", "+");
        str = str.replace("%2E", ".");
        str = str.replace("%2D", "-");
        str = str.replace("%2A", "*");
        str = str.replace("%28", "(");
        str = str.replace("%29", ")");
        str = str.replace("%5F", "_");
        str = decodeURI(str);
        return str;
    }
    else
    {
        return "";
    }
   };

  const rotateKey = async (s, amt) => {
    console.log(amt);
    amt = amt % s.length;
    if (amt < 0)
    {
        amt = s.length + amt;
    }
    if (amt !== 0)
    {
        return (s.substring(s.length - amt, amt) + s.substring(0, s.length - amt));
    }
    else
    {
        return s;
    }
  };

  return (
    <EncrDycrContext.Provider value={{ generateKeyNew, encrypt,decrypt }}>
      {props.children}
    </EncrDycrContext.Provider>
  );
};
export default EncrDycrState;
